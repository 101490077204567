<template>
  <div>
    <v-container fluid grid-list-lg>
      <v-layout row>
        <v-flex xs12 mb-3>
          <v-card>
            <v-toolbar color="transparent" flat>
              <v-toolbar-title>Overview</v-toolbar-title>
              <v-spacer />

              <v-flex xs4>
                <date-picker />
              </v-flex>
            </v-toolbar>

            <v-card-text>
              <visitors-page-views css-classes="page-views" />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row align justify-space-between>
        <v-flex xs3>
          <v-card>
            <v-card-text class="text-xs-center py-2 px-1">
              <visitors-percent-chart class="percent-chart-wrap" />
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs9>
          <general-information />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid grid-list-xl>
      <demography-information />
    </v-container>

    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs3>
          <device-information />
        </v-flex>
        <v-flex xs3>
          <platform-information />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid grid-list-xl>
      <tags-information />
    </v-container>
  </div>
</template>

<script>
import DatePicker from "../../../components/Home/DatePicker"
import VisitorsPercentChart from "../../../components/Home/VisitorsPercentChart"
import visitorsPageViews from "../../../components/Home/VisitorsPageViews"
import GeneralInformation from "../../../components/Home/GeneralInformation"
import DemographyInformation from "../../../components/Home/DemographyInformation"
import DeviceInformation from "../../../components/Home/DeviceInformation"
import PlatformInformation from "../../../components/Home/PlatformInformation"
import TagsInformation from "../../../components/Home/TagsInformation"

export default {
  components: {
    VisitorsPercentChart,
    visitorsPageViews,
    DatePicker,
    GeneralInformation,
    DemographyInformation,
    DeviceInformation,
    PlatformInformation,
    TagsInformation
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.percent-chart-wrap {
  height: 200px;
}
</style>
