<script>
import { Line } from "vue-chartjs"

export default {
  extends: Line,
  mounted() {
    this.gradient = this.$refs.canvas 
      .getContext("2d")
      .createLinearGradient(500, 0, 100, 0)
    this.gradient.addColorStop(0, "#80b6f4")
    this.gradient.addColorStop(1, "#f49080")

    this.renderChart(
      {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
          {
            label: "Visitors",
            fill: false,
            data: [410, 380, 800, 789, 450, 550, 187, 470, 234, 984, 1024, 890],
            borderColor: this.$vuetify.theme.primary,
            pointBorderColor: this.$vuetify.theme.primary,
            pointBackgroundColor: this.$vuetify.theme.primary,
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            shadowBlur: 10,
            shadowColor: "rgba(98, 0, 234, 0.5)",
            pointRadius: 4,
            pointBevelWidth: 3,
            pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
            pointBevelShadowColor: "rgba(98, 0, 234, 0.5)",
            pointHoverRadius: 6,
            pointHoverBevelWidth: 4.5,
            pointHoverInnerGlowWidth: 20,
            pointHoverInnerGlowColor: `rgba(98, 0, 234, 0.5)`,
            pointHoverOuterGlowWidth: 20,
            pointHoverOuterGlowColor: this.$vuetify.theme.secondary,
            tension: 0.15
          },
          {
            label: "Page Views",
            fill: false,
            data: [
              410,
              380,
              800,
              789,
              450,
              550,
              187,
              470,
              234,
              984,
              1024,
              890
            ].map(i => i * 3),
            borderColor: this.$vuetify.theme.secondary,
            pointBorderColor: this.$vuetify.theme.secondary,
            pointBackgroundColor: this.$vuetify.theme.secondary,
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            shadowBlur: 10,
            shadowColor: "rgba(233, 30, 99, 0.5)",
            pointRadius: 4,
            pointBevelWidth: 3,
            pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
            pointBevelShadowColor: "rgba(233, 30, 99, 0.5)",
            pointHoverRadius: 6,
            pointHoverBevelWidth: 4.5,
            pointHoverInnerGlowWidth: 20,
            pointHoverInnerGlowColor: `rgba(233, 30, 99, 0.5)`,
            pointHoverOuterGlowWidth: 20,
            pointHoverOuterGlowColor: this.$vuetify.theme.primary,
            tension: 0.15
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
        legend: {
          display: false
        },
        spanGaps: true,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month"
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value"
              }
            }
          ]
        }
      }
    )
  }
}
</script>
