<template>
  <v-layout row wrap>
    <v-flex xs3>
      <div class="title d-flex align-center title-table">
        Tags by country
      </div>

      <v-select :items="items" label="Select by country" solo hide-details /> 
    </v-flex>

    <v-flex xs3>
      <v-data-table
        :headers="tagsHeaders"
        :items="tagsTable"
        hide-actions
        class="elevation-1 custom-table"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.count }}</td>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      sortBy: "Ukraine",
      items: ["Ukraine", "USA", "England", "France"],
      tagsHeaders: [
        {
          text: "Popular tags",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Posts",
          align: "left",
          sortable: false,
          value: "name"
        }
      ],
      tagsTable: [
        {
          name: "Funny",
          count: 13123
        },
        {
          name: "Car",
          count: 13
        },
        {
          name: "Relationship",
          count: 131423
        }
      ]
    }
  }
}
</script>
