<script>
import { Pie } from "vue-chartjs"
import ChartDataLabels from "chartjs-plugin-datalabels"
export default {
  extends: Pie,
  plugins: [ChartDataLabels], 
  mounted() {
    this.gradient1 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450)
    this.gradient1.addColorStop(0, "rgba(143, 211, 244, 0.7)")
    this.gradient1.addColorStop(1, "rgba(132, 250, 176, 0.7)")

    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2.addColorStop(0, "rgba(72, 1, 255, 0.7)")
    this.gradient2.addColorStop(1, "rgba(121, 24, 242, 0.7)")

    this.renderChart(
      {
        labels: ["Returning visitors", "New visitors"],
        datasets: [
          {
            backgroundColor: [this.gradient1, this.gradient2],
            data: [25, 75]
          }
        ]
      },
      {
        hover: { mode: null },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#ffffff",
            fontSize: 14,
            boxWidth: 14,
            padding: 16
          },
          position: "bottom",
          align: "start",
          onClick: function() {
            return false
          }
        },
        plugins: {
          datalabels: {
            formatter: function(value) {
              return value + "%"
            },
            color: "white",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 20
                }
              },
              value: {
                color: "green",
                font: {
                  weight: "bold",
                  size: 20
                }
              }
            }
          }
        }
      }
    )
  }
}
</script>
