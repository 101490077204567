<template>
  <v-layout row wrap>
    <v-flex xs3>
      <div class="title mb-3">
        Demography
      </div>
      <div color="cyan" dark tabs class="vertical-tabs">
        <v-tabs
          v-model="model"
          hide-slider
          color="#607D8B" 
          dark
          slider-color="yellow"
        >
          <v-tab
            v-for="(demography, index) in demographys"
            :key="index"
            :href="`#tab-${index}`"
            class="subheading"
          >
            {{ demography.title }}
          </v-tab>
        </v-tabs>
      </div>
    </v-flex>

    <v-flex xs9>
      <v-tabs-items v-model="model">
        <v-tab-item
          v-for="(demography, index) in demographys"
          :key="index"
          :value="`tab-${index}`"
          transition="opacity"
          reverse-transition="opacity"
        >
          <div class="demography-list mb-3">
            <div class="demography-list-title title">
              {{ demography.title }}
            </div>
            <div class="demography-list-users title">
              Users
            </div>
            <div class="demography-list-percent title">
              Users, %
            </div>
          </div>

          <v-card
            v-for="(item, i) in demography.value"
            :key="i"
            flat
            class="demography-list demography-list-body"
          >
            <div
              class="demography-list-title demography-list-item"
              v-text="item.lang"
            ></div>
            <div
              class="demography-list-users demography-list-item"
              v-text="item.userCount"
            ></div>
            <div
              class="demography-list-percent demography-list-item"
              v-text="item.userPercent"
            ></div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      model: "tab-0",
      demographys: [
        {
          title: "Language",
          value: [
            {
              lang: "RU",
              userCount: 111,
              userPercent: 11.4
            },
            {
              lang: "UA",
              userCount: 10331,
              userPercent: 235.4
            },
            {
              lang: "UK",
              userCount: 104343,
              userPercent: 12.4
            }
          ]
        },
        {
          title: "Country",
          value: [
            {
              lang: "Russia",
              userCount: 107311,
              userPercent: 751.4
            },
            {
              lang: "Ukraine",
              userCount: 10771,
              userPercent: 455.4
            },
            {
              lang: "United Kingdom",
              userCount: 13,
              userPercent: 1.4
            }
          ]
        },
        {
          title: "City",
          value: [
            {
              lang: "Moscow",
              userCount: 10311,
              userPercent: 71.4
            },
            {
              lang: "Kyiv",
              userCount: 1031,
              userPercent: 45.4
            },
            {
              lang: "Lviv",
              userCount: 103,
              userPercent: 21.4
            }
          ]
        }
      ]
    }
  }
}
</script>
