<template>
  <v-data-table
    :headers="platformHeaders"
    :items="platformTable"
    hide-actions
    class="elevation-1 custom-table"
  > 
    <template slot="items" slot-scope="props">
      <td>{{ props.item.name }}</td>
      <td>{{ props.item.count }}</td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      platformHeaders: [
        {
          text: "Platform info",
          align: "left",
          sortable: false,
          value: "name"
        }
      ],
      platformTable: [
        {
          name: "Uploaded posts",
          count: 133124
        },
        {
          name: "Comments left",
          count: 14123
        }
      ]
    }
  }
}
</script>
