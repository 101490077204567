<template>
  <v-data-table
    :headers="deviceHeaders"
    :items="deviceTable"
    hide-actions
    class="elevation-1 custom-table"
  > 
    <template slot="items" slot-scope="props">
      <td>{{ props.item.name }}</td>
      <td>{{ props.item.users }}</td>
      <td>{{ props.item.percent }}</td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      deviceHeaders: [
        {
          text: "Devices",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Users",
          sortable: false,
          align: "left",
          value: "users"
        },
        {
          text: "Users, %",
          sortable: false,
          align: "left",
          value: "percent"
        }
      ],
      deviceTable: [
        {
          name: "PC",
          users: "123",
          percent: "131"
        },
        {
          name: "Mobile",
          users: "13",
          percent: "13"
        },
        {
          name: "Tablet",
          users: "31",
          percent: "6567"
        }
      ]
    }
  }
}
</script>
