<template>
  <v-layout row>
    <v-flex xs2 sm2 md6>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="date"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field 
          slot="activator"
          v-model="date"
          label="Picker without buttons"
          prepend-icon="event"
          readonly
        />

        <v-date-picker v-model="date" @input="$refs.menu.save(date)">
          <v-btn flat color="primary" @click="menu = false">
            Cancel
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-flex>

    <v-flex xs2 sm2 md6>
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="date2"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="date2"
          label="Picker without buttons"
          prepend-icon="event"
          readonly
        />

        <v-date-picker v-model="date2" @input="$refs.menu2.save(date2)">
          <v-btn flat color="primary" @click="menu2 = false">
            Cancel
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      date: null,
      date2: null,
      menu: false,
      menu2: false
    }
  }
}
</script>
