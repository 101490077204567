<template>
  <v-layout row wrap>
    <template v-for="(infoItem, index) in generalInfo.data">
      <v-flex :key="index" xs12 sm6 md3>
        <div
          flat
          dark 
          class="transparent text-xs-center box-glow pa-2 user-info-wrap"
        >
          <v-card-text class="pa-0 user-info-block">
            <h3 class="display-2 text--darken-1 count-text">
              {{ infoItem.statistic }}
            </h3>
            <span class="subheading">
              {{ infoItem.title }}
            </span>
          </v-card-text>
        </div>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      generalInfo: {
        data: [
          {
            title: "All users",
            statistic: "14 944"
          },
          {
            title: "New users",
            statistic: "9 342"
          },
          {
            title: "Sessions",
            statistic: "18 598"
          },
          {
            title: "Average session time",
            statistic: "00:43:21"
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.user-info-wrap {
  height: 216px;
}

.user-info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user-info-block .count-text {
  display: flex;
  align-items: center;
  flex-grow: 2;
}
</style>
